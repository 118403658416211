import { useState } from "react";
import { useFixtureFormGuide } from "./fixtureHook";
import { Grid, Paper } from "@mui/material";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Divider, { dividerClasses } from '@mui/material/Divider';
import { Link } from "react-router-dom";

const styles = {
    formFixture: {
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'center',
        flexGrow: '3',
        display: 'grid',
        gridTemplateColumns: '1fr 42px 1fr',
        columnGap: '5px'
    },
    teamFormRow: {
        display: 'flex',
        flexDirection: 'column',
        gap: '3px'
    },
    teamFormFixture: {
        height: '38px'
    },
    teamLogos: {
        width: '24px', 
        height: '24px'
    }
}

export default function FixtureFormGuide({ fixture_id, teams, contentSetter, isMobile}) {

    const [fixtureFormGuideResponse, setFixtureFormGuideResponse] = useState({ isLoading: true, data: null });

    useFixtureFormGuide(fixture_id, setFixtureFormGuideResponse);

    var homeData = [];

    var awayData = [];
      
    if(fixtureFormGuideResponse == null || 
       fixtureFormGuideResponse.isLoading || 
       (!fixtureFormGuideResponse.isLoading && fixtureFormGuideResponse.data === null)
    ) 
    {
        return <></>
    }

    homeData = fixtureFormGuideResponse.data.fixture_outcomes.filter((result, index) => result.team_id == teams.home_team.id).map(result => ({
        fixture_id: result.fixture_id,
        opponent_name: result.opponent_name,
        fixture_name: result.fixture_name,
        team_name: teams.home_team.name,
        fill: result.fixture_outcome === 1 ? '#39b57a' : result.fixture_outcome === 2 ? '#e35c47' : '#a4a9b3',
        opponent_logo: result.opponent_logo,
        team_logo: teams.home_team.logo,
        fixture_outcome: result.fixture_outcome,
        result: `${result.home_team_score}-${result.away_team_score}`,
        is_home: result.is_home
    }));

    awayData = fixtureFormGuideResponse.data.fixture_outcomes.filter((result, index) => result.team_id == teams.away_team.id).map(result => ({
        fixture_id: result.fixture_id,
        opponent_name: result.opponent_name,
        team_name: teams.home_team.name,
        fixture_name: result.fixture_name,
        fill: result.fixture_outcome === 1 ? '#39b57a' : result.fixture_outcome === 2 ? '#e35c47' : '#a4a9b3',
        opponent_logo: result.opponent_logo,
        team_logo: teams.away_team.logo,
        fixture_outcome: result.fixture_outcome,
        result: `${result.home_team_score}-${result.away_team_score}`,
        is_home: result.is_home
    }));

    if(homeData.length < 1 && awayData.length < 1) {
        return <></>
    }

    return (
        <Paper elevation={1} className="mb-2 br-15 widget_fade_in no_side_padding" sx={{[`& .${dividerClasses.root}`]: { mx: 0, height: '0.5px', opacity: '0.1' }}}>
            <div className='widget_heading' style={{textAlign: 'center', padding: '10px 10px 10px 10px' }}>
                Form Guide
            </div>
            <Divider />
            <MDBRow className="no_side_padding pb-3">
                <MDBCol className="no_side_padding" style={styles.teamFormRow} size="6">
                    {
                        homeData.map((result, index) => (
                            <Link key={`fixture_form_${result.fixture_id}_${teams.home_team.id}`} to={`/fixture/${result.fixture_id}/${result.fixture_name}`}>
                                <MDBRow style={styles.teamFormFixture} >
                                    <div style={styles.formFixture}>
                                        <div style={{display: 'flex', justifyContent: 'right'}}>
                                            <img src={result.is_home ? result.team_logo : result.opponent_logo} style={styles.teamLogos} />
                                        </div>
                                        <div style={{ontSize: '14px', color: 'white', display: 'flex', justifyContent: 'center', gap:'5px', padding: '5px'}}>
                                            <span style={{backgroundColor: result.fill, width: '100%', borderRadius: '3px'}}>
                                                {result.result}
                                            </span>
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'left' }}>
                                            <img src={result.is_home ? result.opponent_logo : result.team_logo} style={styles.teamLogos} />
                                        </div>
                                    </div>
                                </MDBRow>
                                <Divider />
                            </Link>
                        ))
                    }
                </MDBCol>
                <MDBCol className="no_side_padding" style={styles.teamFormRow} size="6">
                    {
                        awayData.map((result, index) => (
                            <Link key={`fixture_form_${result.fixture_id}_${teams.away_team.id}`} to={`/fixture/${result.fixture_id}/${result.fixture_name}`}>
                                <MDBRow style={styles.teamFormFixture}>
                                    <div style={styles.formFixture}>
                                        <div style={{display: 'flex', justifyContent: 'right'}}>
                                            <img src={result.is_home ? result.team_logo : result.opponent_logo} style={styles.teamLogos} />
                                        </div>
                                        <div style={{ontSize: '14px', color: 'white', display: 'flex', justifyContent: 'center', gap:'5px', padding: '5px'}}>
                                            <span style={{backgroundColor: result.fill, width: '90%', borderRadius: '3px'}}>
                                                {result.result}
                                            </span>
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'left' }}>
                                            <img src={result.is_home ? result.opponent_logo : result.team_logo} style={styles.teamLogos} />
                                        </div>
                                    </div>
                                </MDBRow>
                                <Divider />
                            </Link>
                        ))
                    }
                </MDBCol>
            </MDBRow>
        </Paper>
    )

}
