import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { MDBRow, MDBCol } from 'mdbreact';
import { ApplicationState } from '../../../store/index';
import * as ApplicationDataStore from '../../../store/application-state';
import { league_selection_item } from '../dashboard_system/interface_store';
import SecondaryDashboard from './secondary_dashboard';
import { HandleDefaultMenuSwitching } from '../dashboard_system/dashboard_switcher';
import StatManLogo from '../../../images/stat_man_logo_aqua.jpg';
import { iselected_content_dashboard_type, iselected_secondary_dashboard_type, primary_dashboard_menu, iselected_mobile_dashboard_type } from '../dashboard_system/dashboard_interfaces';
import { BottomNavigation, BottomNavigationAction, Box, Skeleton } from '@mui/material';
import { Breakpoint } from 'react-socks';
import DashboardFooter from "./dashboard_footer";
import { withRouter } from "./withRouter";
import { getFixtureName } from "../../../api/fixtureAPI";
import '../../../mobile/application/dashboards/css/mobile_dashboard.css';
import '../css/primary_dashboard.css';
import '../css/content_dashboard.css';
import '../css/dashboard.css';
import { Link } from "react-router-dom";
import DashboardMenu from "../../common/dashboard-menu";
import HideAppBar from '../../../Components/navigation/top_menu.js';
import MobileDashboard from '../../../mobile/application/dashboards/views/mobile_dashboard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import { BsPerson } from "react-icons/bs";

type PrimaryDashboardProps = ApplicationState & 
    typeof ApplicationDataStore.actionCreators & {
        router: {
            params: {
                fixture_id?: string;
                fixture_name?: string;
            },
            location: {
                pathname?: string
            }
            navigate: (path: string, options?: { replace?: boolean }) => void;
        };
    };

class PrimaryDashboard extends PureComponent<PrimaryDashboardProps> {
    componentDidMount() {
        const { fixture_id, fixture_name } = this.props.router.params;

        this.props.requestLeagueSelections();

        if(fixture_id) {
            let fixtureId = parseInt(fixture_id);
            if(!isNaN(fixtureId) && fixtureId !== this.props.content_dashboard_state.selectedContentID) {
                this.props.switchContentDashboard(iselected_content_dashboard_type.Fixture, fixtureId);
                this.props.switchMobileDashboard(iselected_mobile_dashboard_type.Fixture, fixtureId);
            }
        }
    }

    async componentDidUpdate() {
        const { fixture_id, fixture_name } = this.props.router.params;
        const currentPath = this.props.router.location.pathname;

        if (fixture_id) {
            try {
                let fixtureId = parseInt(fixture_id);

                if (isNaN(fixtureId)) {
                    throw new Error(`Invalid fixture ID: ${fixture_id}`);
                }
    
                let hasStatPackInclude = currentPath?.includes('/stat-pack') ?? false;

                if (hasStatPackInclude) {
                    if (
                        this.props.content_dashboard_state.selectedContentID !== fixtureId ||
                        this.props.content_dashboard_state.selectedDashboardType !== iselected_content_dashboard_type.FixtureStatPack
                    ) 
                    {
                        this.props.switchContentDashboard(iselected_content_dashboard_type.FixtureStatPack, fixtureId);
                        this.props.switchMobileDashboard(iselected_mobile_dashboard_type.FixtureStatPack, fixtureId);
                    }
                } 
                else 
                {
                    if (
                        this.props.content_dashboard_state.selectedContentID !== fixtureId ||
                        this.props.mobile_dashboard_state.selectedDashboardType !== iselected_mobile_dashboard_type.Fixture
                    ) 
                    {
                        this.props.switchContentDashboard(iselected_content_dashboard_type.Fixture, fixtureId);
                        this.props.switchMobileDashboard(iselected_mobile_dashboard_type.Fixture, fixtureId);
                    }
                }
    
                // Handle fixture name update
                const fixtureName = await getFixtureName(fixtureId);
                if (fixtureName !== fixture_name || fixture_name === '' || !fixture_name) {
                    this.updateUrlWithCorrectMatchName(fixture_id, fixtureName, hasStatPackInclude);
                }
            } catch (error) {
                console.error(`Unable to parse fixture ${fixture_id} and set dashboards`, error);
            }
        }
    }    
    
    updateUrlWithCorrectMatchName(fixture_id:string | undefined, correctMatchName:string, hasStatPackInclude:boolean) {
        const fragment = window.location.hash.replace("#", "");
        this.props.router.navigate(`/fixture/${fixture_id}/${correctMatchName}` + (hasStatPackInclude ? '/stat-pack' : '') + (fragment && `#${fragment}`), { replace: true });
    }

    leagueSelectionOnClick = (leagueid: number | null) => {
        const { switchPrimaryDashboard, switchSecondaryDashboard, switchContentDashboard, content_dashboard_state } = this.props;
        
        switchPrimaryDashboard(leagueid);
        switchSecondaryDashboard(this.props.secondary_dashboard_state.selectedDashboardType);

        if (content_dashboard_state.selectedDashboardType === iselected_content_dashboard_type.Standing) {
            switchContentDashboard(content_dashboard_state.selectedDashboardType, leagueid ?? -1);
        }

        window.scrollTo(0, 0);
    };

    standingsSelectionOnClick = (league_id: number | null) => {
        this.props.switchContentDashboard(iselected_content_dashboard_type.Standing, league_id ?? -1);
        window.scrollTo(0, 0);
    };

    render() {
        const { site_date, primary_dashboard_state, switchSiteDate, mobile_dashboard_state, switchMobileDashboard } = this.props;
        return (
            <React.Fragment>
                <Breakpoint className="mobile_content" medium down>
                    <Box component="main" sx={{ flexGrow: 1 }}>
                        <HideAppBar selected_date={site_date} set_date={switchSiteDate} setter={() => switchMobileDashboard(1, null)} />
                        <MobileDashboard />
                        
                        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, boxShadow: '0 1px 8px 2px #0000003d' }}>
                            <BottomNavigation
                                showLabels
                                value={mobile_dashboard_state.selectedDashboardType}
                                onChange={(event, newValue) => {
                                    switchMobileDashboard(newValue, null);
                                }}
                            >
                            {/* Live Scores Navigation */}
                            <BottomNavigationAction
                                    label="Matches"
                                    value={2}
                                    icon={<DashboardIcon />}
                                    component={Link}
                                    to="/"
                                    style={{
                                    float: 'left',
                                    display: 'flex'
                                }}
                            />
                            {/* Team Statistics Navigation */}
                            <BottomNavigationAction
                                    label="Team Statistics"
                                    value={14}
                                    icon={<BarChartIcon />}
                                    component={Link}
                                    to="/"
                                    style={{
                                    float: 'left',
                                    display: 'flex'
                                }}
                            />
                            {/* Team Statistics Navigation */}
                            <BottomNavigationAction
                                    label="Player Statistics"
                                    value={15}
                                    icon={<BsPerson />}
                                    component={Link}
                                    to="/"
                                    style={{
                                    float: 'left',
                                    display: 'flex'
                                }}
                            />
                            </BottomNavigation>
                        </Box>
                    </Box>
                </Breakpoint>
                <Breakpoint large up>
                    <MDBRow className="row_primary_dashboard">
                        <Box sx={{ display: "flex" }}>
                            <DashboardMenu 
                                dashboardProps={this.props}
                                HandleDefaultMenuSwitching={HandleDefaultMenuSwitching} 
                                LeagueSelectionOnClick={this.leagueSelectionOnClick}
                                MenuItems={primary_dashboard_menu.menu_items}
                            />
                            <Box component="main" sx={{ flexGrow: 1 }}>
                                <MDBCol className="column_no_side_padding primary_dashboard_secondary_column">
                                    <MDBRow>
                                        <MDBCol style={{ padding: '10px 0px 10px 0px' }} size="12">
                                            <SecondaryDashboard />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </Box>
                        </Box>
                    </MDBRow>
                </Breakpoint>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state,
    ApplicationDataStore.actionCreators,
    null,
    { forwardRef: true }
  )(withRouter(PrimaryDashboard));
