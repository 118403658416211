import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import MobileGoalsPack from "../../statpack/mobile_goals_pack";

export default function TeamStatisticsDashboard({ site_date, contentSetter, switch_site_date }) {
    return (
        <MDBRow>
            <MDBCol className="no_side_padding" size="12">
                <MobileGoalsPack date={site_date} switch_site_date={switch_site_date} />
            </MDBCol>
        </MDBRow>
    )
}