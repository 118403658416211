import React, { useState } from "react";
import GoalsPack from "../../statpack/goals_pack";
import FoulsPack from "../../statpack/fouls_pack";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import FoulsDrawnPack from "../../statpack/fouls_drawn_pack";
import ShotsOnTargetPack from "../../statpack/shots_on_target_pack";
import SavesPack from "../../statpack/saves_pack";
import TotalShotsPack from "../../statpack/shots_pack";
import TotalTacklesPack from "../../statpack/tackles_pack";
import PlayerGoalsPack from "../../statpack/player_goals_pack";
import AssistsPack from "../../statpack/assists_pack";

const styles = {
    statContainer: {
        flexShrink: 0,
        flexGrow: 1,
        borderRadius: "5px",
        padding: "5px",
        display: "flex",
        justifyContent: "space-evenly",
        scrollSnapAlign: "start",
        cursor: 'pointer'
    }
};

export default function HomeDashboard(props) {

    const { contentSetter, isMobile, site_date } = props;

    const [statType, setStatType] = useState('7');

    const selectStatType = (event) => {
        if (setStatType !== event.target.value) {
            setStatType(event.target.value);
        }
    };

    return (
        <React.Fragment>
            <Grid container spacing={{ xs: 1, md: 1, lg: 1, xl: 1 }} columns={{ xs: 4, sm: 12, md: 12 }}>
                <Grid style={{paddingBottom: '10px'}} item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <GoalsPack date={site_date} />
                </Grid>
                <Grid style={{paddingBottom: '10px'}} item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <FormControl fullWidth className="mb-2" style={{backgroundColor: 'white'}}>
                        <Select
                            size="small"
                            value={statType}
                            label="Statistic Type"
                            onChange={selectStatType}
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{ "& .MuiInputLabel-root": { display: "none" } }}
                        >
                        {
                             [
                                { statisticType: '1', statisticName: "Fouls" }, 
                                { statisticType: '2', statisticName: "Fouls Drawn" }, 
                                { statisticType: '3', statisticName: "Shots On Target" }, 
                                { statisticType: '4', statisticName: "Saves" }, 
                                { statisticType: '5', statisticName: "Shots" },
                                { statisticType: '6', statisticName: "Tackles" },
                                { statisticType: '7', statisticName: "Goals" },
                                { statisticType: '8', statisticName: "Assists" }
                            ].map((statistic, index) => {
                                return (<MenuItem key={"player_statistic_select_option_" + statistic.statisticType} value={statistic.statisticType}>{statistic.statisticName}</MenuItem>)
                            })
                        }
                        </Select>
                    </FormControl>
                    {/* <div style={{display: 'flex', gap: '3px', marginBottom: '10px'}}>
                        {
                            [
                                { statisticType: 1, statisticName: "Fouls" }, 
                                { statisticType: 2, statisticName: "Fouls Drawn" }, 
                                { statisticType: 3, statisticName: "Shots On Target" }, 
                                { statisticType: 4, statisticName: "Saves" }, 
                                { statisticType: 5, statisticName: "Shots" },
                                { statisticType: 6, statisticName: "Tackles" }
                            ].map((statistic, index) => {
                                return (<span 
                                        onClick={() => selectStatType(statistic.statisticType)} 
                                        style={{ ...styles.statContainer, 
                                            border: statType === statistic.statisticType ? "2px solid #037ab0" : "1px solid #d9d9d9", 
                                            backgroundColor: statType === statistic.statisticType ? "#037ab0" : "#d9d9d9",
                                            color: statType === statistic.statisticType ? "white" : "black" 
                                    }}>
                                        {statistic.statisticName}
                                    </span>
                                )
                            })
                        }
                    </div> */}
                    {statType === '1' && <FoulsPack isMobile={isMobile} date={site_date} />}
                    {statType === '2' && <FoulsDrawnPack isMobile={isMobile} date={site_date} />}
                    {statType === '3' && <ShotsOnTargetPack isMobile={isMobile} date={site_date} />}
                    {statType === '4' && <SavesPack isMobile={isMobile} date={site_date} />}
                    {statType === '5' && <TotalShotsPack isMobile={isMobile} date={site_date} />}
                    {statType === '6' && <TotalTacklesPack isMobile={isMobile} date={site_date} />}
                    {statType === '7' && <PlayerGoalsPack isMobile={isMobile} date={site_date} />}
                    {statType === '8' && <AssistsPack isMobile={isMobile} date={site_date} />}
                </Grid>
            </Grid>
        </React.Fragment>
    )
}