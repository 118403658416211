import axios from 'axios';
import { useEffect } from 'react';
import configuration from '../../config.json';

export const useShotsOnTargetPack = (date, setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        var result = { isLoading: false, data: null, fixtureId: null };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}playerstatistics/shotsontargetpack?fixtureDate=${date !== null && date !== undefined && date !== '' ? date : ""}`);
        
        if (response.status === 200) {
          result.data = response.data;
          if(isMounted) {
            setter(result);
          }
        }
        else {
          if(isMounted) {
            setter({ isLoading: false, data: null, fixtureId: null });
          }
        }
      } 
      catch (error) {
        if(isMounted) {
          setter({ isLoading: false, data: null, fixtureId: null });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [date]);
};

export const useFoulsDrawnPack = (date, setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        var result = { isLoading: false, data: null, fixtureId: null };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}playerstatistics/foulsdrawnpack?fixtureDate=${date !== null && date !== undefined && date !== '' ? date : ""}`);
        
        if (response.status === 200) {
          result.data = response.data;
          if(isMounted) {
            setter(result);
          }
        }
        else {
          if(isMounted) {
            setter({ isLoading: false, data: null, fixtureId: null });
          }
        }
      } 
      catch (error) {
        if(isMounted) {
          setter({ isLoading: false, data: null, fixtureId: null });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [date]);
};

export const usePlayerGoalsPack = (date, setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        var result = { isLoading: false, data: null, fixtureId: null };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}playerstatistics/goalspack?fixtureDate=${date !== null && date !== undefined && date !== '' ? date : ""}`);
        
        if (response.status === 200) {
          result.data = response.data;
          if(isMounted) {
            setter(result);
          }
        }
        else {
          if(isMounted) {
            setter({ isLoading: false, data: null, fixtureId: null });
          }
        }
      } 
      catch (error) {
        if(isMounted) {
          setter({ isLoading: false, data: null, fixtureId: null });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [date]);
};

export const useAssistsPack = (date, setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        var result = { isLoading: false, data: null, fixtureId: null };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}playerstatistics/assistspack?fixtureDate=${date !== null && date !== undefined && date !== '' ? date : ""}`);
        
        if (response.status === 200) {
          result.data = response.data;
          if(isMounted) {
            setter(result);
          }
        }
        else {
          if(isMounted) {
            setter({ isLoading: false, data: null, fixtureId: null });
          }
        }
      } 
      catch (error) {
        if(isMounted) {
          setter({ isLoading: false, data: null, fixtureId: null });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [date]);
};

export const useFoulsPack = (date, setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        var result = { isLoading: false, data: null, fixtureId: null };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}playerstatistics/foulspack?fixtureDate=${date !== null && date !== undefined && date !== '' ? date : ""}`);
        
        if (response.status === 200) {
          result.data = response.data;
          if(isMounted) {
            setter(result);
          }
        }
        else {
          if(isMounted) {
            setter({ isLoading: false, data: null, fixtureId: null });
          }
        }
      } 
      catch (error) {
        if(isMounted) {
          setter({ isLoading: false, data: null, fixtureId: null });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [date]);
};

export const useSavesPack = (date, setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        var result = { isLoading: false, data: null, fixtureId: null };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}playerstatistics/savespack?fixtureDate=${date !== null && date !== undefined && date !== '' ? date : ""}`);
        
        if (response.status === 200) {
          result.data = response.data;
          if(isMounted) {
            setter(result);
          }
        }
        else {
          if(isMounted) {
            setter({ isLoading: false, data: null, fixtureId: null });
          }
        }
      } 
      catch (error) {
        if(isMounted) {
          setter({ isLoading: false, data: null, fixtureId: null });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [date]);
};

export const useTotalShotsPack = (date, setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        var result = { isLoading: false, data: null, fixtureId: null };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}playerstatistics/totalshotspack?fixtureDate=${date !== null && date !== undefined && date !== '' ? date : ""}`);
        
        if (response.status === 200) {
          result.data = response.data;
          if(isMounted) {
            setter(result);
          }
        }
        else {
          if(isMounted) {
            setter({ isLoading: false, data: null, fixtureId: null });
          }
        }
      } 
      catch (error) {
        if(isMounted) {
          setter({ isLoading: false, data: null, fixtureId: null });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [date]);
};

export const useTotalTacklesPack = (date, setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        var result = { isLoading: false, data: null, fixtureId: null };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}playerstatistics/totaltacklespack?fixtureDate=${date !== null && date !== undefined && date !== '' ? date : ""}`);
        
        if (response.status === 200) {
          result.data = response.data;
          if(isMounted) {
            setter(result);
          }
        }
        else {
          if(isMounted) {
            setter({ isLoading: false, data: null, fixtureId: null });
          }
        }
      } 
      catch (error) {
        if(isMounted) {
          setter({ isLoading: false, data: null, fixtureId: null });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [date]);
};

export const useGoalsPack = (date, setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        var result = { isLoading: false, data: null };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}teamstatistics/goalspack?fixtureDate=${date !== null && date !== undefined && date !== '' ? date : ""}`);
        
        if (response.status === 200) {
          result.data = response.data;
        }

        if(isMounted) {
          setter(result);
        }
      } 
      catch (error) {
        if(isMounted) {
          setter({ isLoading: false, data: null });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [date]);
};

export const useStatPack = (fixture_id, setter) => {
    useEffect(() => {
      let isMounted = true;
  
      const fetchData = async () => {
        try {

          var result = { isLoading: false, data: null, fixture_id: fixture_id };

          const response = await axios.get(`${configuration.FRONT_END_API_v2}statpack/${fixture_id === null ? -1 : fixture_id}`);
          
          if (response.status === 200) {
            result.data = response.data;
          }

          if(isMounted) {
            setter(result);
          }
        } 
        catch (error) {
          if(isMounted) {
            setter({ isLoading: false, data: null, fixture_id: fixture_id });
          }
        }
      };
  
      fetchData();
  
      return () => {
        isMounted = false;
      };
    }, [fixture_id]);
  };