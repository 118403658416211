import React, { useState, useRef } from "react";
import { useAssistsPack } from "./statPackHook";
import { Fade, Pagination, Paper, styled } from "@mui/material";
import Divider, { dividerClasses } from "@mui/material/Divider";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { GetStatGradientColor } from "../../common/helpers/rating_helper";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DateController } from "../shared/date/button_date_picker";

const BorderLinearProgress = styled(LinearProgress)(({ theme, percentile }) => ({
    height: 10,
    borderRadius: 5,
    width: "100%",
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: GetStatGradientColor(percentile),
    },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

const styles = {
    footer: {
        fontSize: "12px",
        paddingTop: "5px",
        paddingBottom: "5px",
        color: "#737373",
    },
    fixtureContainer: {
        flexShrink: 0,
        width: "80px",
        borderRadius: "5px",
        padding: "5px",
        display: "flex",
        justifyContent: "space-evenly",
        scrollSnapAlign: "start",
        cursor: 'pointer'
    },
    arrowButton: {
        height: '42px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
    }
};

export default function AssistsPack({ date, switch_site_date, isMobile }) {
    const [statPackResponse, setStatPack] = useState({ isLoading: false, data: null, fixtureId: null });
    const [currentPage, setCurrentPage] = useState(1);
    const fixtureScrollContainer = useRef(null);

    useAssistsPack(date, setStatPack);

    if (!statPackResponse || (!statPackResponse.data && statPackResponse.isLoading) || (!statPackResponse.data && !statPackResponse.isLoading)) {
        return <></>;
    }

    if (statPackResponse.data.players.length < 1) {
        return <></>;
    }

    const selectedFixture = statPackResponse.fixtureId === null ? 
        null : 
        statPackResponse.data.fixtures.find(a => a.fixture_id === statPackResponse.fixtureId);

    const players = selectedFixture === null ? statPackResponse.data.players : statPackResponse.data.players.filter(player => player.team_id === selectedFixture.home_team_id || player.team_id === selectedFixture.away_team_id);

    const itemsPerPage = 20;
    const pageCount = Math.ceil(players.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;

    const currentItems = players.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= pageCount) {
            setCurrentPage(page);
        }
    };

    const scrollFixtures = (direction) => {
        const container = fixtureScrollContainer.current;
        if (container) {
            const scrollStep = container.firstChild.offsetWidth + 3;
            container.scrollBy({
                left: direction === "left" ? -scrollStep : scrollStep,
                behavior: "smooth",
            });
        }
    };

    const selectFixture = (fixture_id) => {
        if (fixture_id === statPackResponse.fixtureId) {
            setStatPack((prevState) => ({
                ...prevState,
                fixtureId: null
            }));
        } else {
            setStatPack((prevState) => ({
                ...prevState,
                fixtureId: fixture_id
            }));
        }
    };

    const fixtures = statPackResponse?.data?.fixtures ?? [];

    return (
        <Fade style={{ transformOrigin: '0 0 0' }} in={true}  {...{ timeout: 500 }}>
            <Paper elevation={1} sx={{ [`& .${dividerClasses.root}`]: { mx: 0, height: "0.5px", opacity: "0.1" } }} className="dashboard_widget pb-2 br-15">
                <div style={{display: 'flex'}}>
                    <label className="dashboard_widget_controls">
                        <span>Player Assists</span>
                    </label>
                    {isMobile && <span>
                        <DateController selected_date={date} set_date={switch_site_date} />
                    </span>}
                </div>
                <Divider className="mt-1" variant="middle" />
                <MDBRow style={styles.footer}>
                    <MDBCol>
                        <HelpOutlineIcon sx={{ fontSize: "14px", position: "relative", top: "-2px" }} />
                        {" "}Players are ordered by total assists. 
                        The bar graph shows how a player compares in assists to other players in their competition for the selected group of fixtures. 
                    </MDBCol>
                </MDBRow>
                {
                    fixtures.length > 0 && 
                    <>
                        <Divider className="mt-1" variant="middle" />
                        <div style={{ display: "flex", alignItems: "center", padding: "5px" }}>
                            {/* Left Arrow */}
                            <span onClick={() => scrollFixtures("left")} style={styles.arrowButton}>
                                <ArrowBackIosIcon color="#037ab0" />
                            </span>
                            {/* Fixture Scroll Container */}
                            <div
                                ref={fixtureScrollContainer}
                                style={{
                                    display: "flex",
                                    scrollSnapType: "x mandatory",
                                    gap: "3px",
                                    overflowX: 'scroll',
                                    scrollbarWidth: 'none',
                                    width: "386px",
                                }}
                            >
                                {statPackResponse.data.fixtures.map((fixture) => (
                                    <span onClick={() => selectFixture(fixture.fixture_id)} key={fixture.fixture_id + "_fixture_scroll"} style={{ ...styles.fixtureContainer, border: statPackResponse.fixtureId === fixture.fixture_id ? "2px solid #037ab0" : "1px solid #d9d9d9" }}>
                                        <img src={fixture.home_team_logo} alt={fixture.home_team_name + " logo"} style={{ width: "30px", height: "30px" }} />
                                        <img src={fixture.away_team_logo} alt={fixture.away_team_name + " logo"} style={{ width: "30px", height: "30px" }} />
                                    </span>
                                ))}
                            </div>
                            {/* Right Arrow */}
                            <span onClick={() => scrollFixtures("right")} style={styles.arrowButton}>
                                <ArrowForwardIosIcon color="#037ab0" />
                            </span>
                        </div>
                    </>
                }
                <Divider className="mt-1" variant="middle" />
                <div style={{ paddingBottom: "15px" }}>
                    {currentItems.map((player) => (
                        <React.Fragment key={"fouls_pack_" + player.id}>
                            <PlayerRow player={player} />
                            <Divider />
                        </React.Fragment>
                    ))}
                </div>
                <Divider />
                <Pagination className="mt-2" page={currentPage} count={pageCount} onChange={(event, page) => handlePageChange(page)} color="primary" />
            </Paper>
        </Fade>
    );
}

function PlayerRow({ player }) {
    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-start", padding: "5px 5px 5px 10px", fontSize: "16px" }}>
            <div style={{ display: "flex", gap: "5px", padding: "5px 0px 5px 10px" }}>
                <span style={{ display: "flex", alignItems: "center", marginRight: "6px" }}>
                    <img src={player.team_logo} alt={player.team_name + " logo"} style={{ width: "26px", height: "26px" }} />
                </span>
                <span style={{ display: "flex", alignItems: "center", marginRight: "6px" }}>
                    <img src={player.image} alt={player.name + " image"} style={{ borderRadius: "50%", border: "1px solid #f1f1f1", width: "26px", height: "26px" }} />
                </span>
                <span style={{ display: "flex", flexDirection: "column", alignItems: "center", lineHeight: "18px", maxWidth: "165px" }}>
                    <label className="elipsis_text_wrap" style={{ fontWeight: "500", textAlign: "left", width: "100%", fontSize: "15px" }}>
                        {player.name}
                    </label>
                    <label style={{ fontSize: "12px", color: "#707070", textAlign: "left", width: "100%" }}>
                        {player.total} Assists, {player.stat_per_ninety} Per 90
                    </label>
                </span>
            </div>
            <div style={{ marginLeft: "auto", display: "flex", flexDirection: "column", width: "120px", justifyContent: "center", alignItems: "center", padding: "2px" }}>
                <BootstrapTooltip title={"Top " + player.rank_percentage + "%"} placement="bottom">
                    <BorderLinearProgress variant="determinate" percentile={player.rank_percentage} value={player.rank_percentage} />
                </BootstrapTooltip>
            </div>
        </div>
    );
}
