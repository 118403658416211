import { useState } from "react"
import { useFixtureGoalPeriods } from "./fixtureHook"
import { Grid, Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Divider, { dividerClasses } from '@mui/material/Divider';
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import PercentIcon from '@mui/icons-material/Percent';
import NumbersIcon from '@mui/icons-material/Numbers';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const styles = {
    periodCount: {
        height: '32px',
        width: '32px',
        paddingTop: '3px',
        paddingBottom: '2px',
        textAlign: 'center',
        alignSelf: 'center',
        color: 'white',
        fontSize: '14px',
        outline: '0.5px solid white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '500'
    },
    teamLogo: {
        width: '20px',
        height: '20px',
        marginRight: '6px'
    },
    teamDetails: {
        fontSize: '16px'
    },
    scored: {
        backgroundColor: '#39b57a'
    },
    conceded: { 
        backgroundColor: '#e35c47'
    },
    topFirstPeriod: {
        borderTopLeftRadius: '5px'
    },
    bottomFirstPeriod: {
        borderBottomLeftRadius: '5px'
    },
    topLastPeriod: {
        borderTopRightRadius: '5px'
    },
    bottomLastPeriod: {
        borderBottomRightRadius: '5px'
    },
    percentage: {
        fontSize: '12px'
    },
    footer: {
        fontSize: '12px',
        padding: '10px 10px',
        color: '#737373'
    }
}

export default function FixtureGoalPeriods({ fixture_id, teams, contentSetter, schedule, fixture }) {

    const [goalPeriodsResponse, setGoalPeriodsResponse] = useState({ isLoading: true, data: null });
    const [showPercentage, setShowPercentage] = useState(false)

    useFixtureGoalPeriods(fixture_id, setGoalPeriodsResponse);

    if(!goalPeriodsResponse.data || goalPeriodsResponse.isLoading) {
        return <></>
    }

    const handleChange = (event, showPer) => {
        if(showPer !== null) {
            setShowPercentage(showPer);
        }
    };

    const { home_team_goal_periods, away_team_goal_periods } = goalPeriodsResponse.data;

    return (
        <Paper elevation={1} className="mb-2 br-15 widget_fade_in no_side_padding" sx={{[`& .${dividerClasses.root}`]: { mx: 0, height: '0.5px', opacity: '0.1' }}}>
            <div className='widget_heading' style={{textAlign: 'center', padding: '10px 10px 10px 10px' }}>
                Goal Periods
            </div>
            <Divider />
            <MDBRow>
                <MDBCol style={{display: 'flex', justifyContent: 'flex-start', padding: '10px 10px'}}>
                    <ToggleButtonGroup
                        style={{fontSize: '8px'}}
                        color="primary"
                        value={showPercentage}
                        exclusive
                        onChange={handleChange}
                        aria-label="show-percentage"
                        size="small"
                    >
                        <ToggleButton value={false}>
                            Count
                        </ToggleButton>
                        <ToggleButton value={true}>
                            Percentage
                        </ToggleButton>
                    </ToggleButtonGroup>
                </MDBCol>
            </MDBRow>
            <MDBRow className="no_side_padding pb-1 pt-1">
                <MDBCol size="12" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', padding: '5px 15px 5px 10px' }}>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <div style={{display: 'flex', fontWeight: '500', justifyContent: 'space-evenly', fontSize: '12px', width:'192px', padding: '3px 0px 3px 0px' }}>
                        <span style={{position: 'relative', left: '-15px'}}>00'</span>
                            <span style={{position: 'relative', left: '-11px'}}>15'</span>
                            <span style={{position: 'relative', left: '-5px'}}>30'</span>
                            <span style={{position: 'relative', left: '1px'}}>45'</span>
                            <span style={{position: 'relative', left: '7px'}}>60'</span>
                            <span style={{position: 'relative', left: '13px'}}>75'</span>
                            <span style={{position: 'relative', left: '17px'}}>90'</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', flexGrow: '1'}}>
                        <img src={teams.home_team.logo} style={styles.teamLogo} alt={teams.home_team.name + " name"} />
                        <span style={styles.teamDetails}>Scored</span>
                        <div style={{ display: 'flex', marginLeft: 'auto' }}>
                            <div style={{ display: 'flex' }}>
                                {(showPercentage ? 
                                [
                                    home_team_goal_periods.scoring_period_0_15_percentage.toFixed(1), 
                                    home_team_goal_periods.scoring_period_15_30_percentage.toFixed(1), 
                                    home_team_goal_periods.scoring_period_30_45_percentage.toFixed(1),
                                    home_team_goal_periods.scoring_period_45_60_percentage.toFixed(1), 
                                    home_team_goal_periods.scoring_period_60_75_percentage.toFixed(1), 
                                    home_team_goal_periods.scoring_period_75_90_percentage.toFixed(1) 
                                ] : 
                                [ 
                                    home_team_goal_periods.scoring_period_0_15_count, 
                                    home_team_goal_periods.scoring_period_15_30_count, 
                                    home_team_goal_periods.scoring_period_30_45_count,
                                    home_team_goal_periods.scoring_period_45_60_count, 
                                    home_team_goal_periods.scoring_period_60_75_count, 
                                    home_team_goal_periods.scoring_period_75_90_count 
                                ]).map((a, index) => (
                                    <span 
                                        key={`goal-period-${index}-row-1`} 
                                        style={{ 
                                            ...styles.periodCount, 
                                            ...styles.scored, 
                                            ...(index === 0 ? styles.topFirstPeriod : {}),
                                            ...(index === 5 ? styles.topLastPeriod : {}),
                                            ...(showPercentage ? styles.percentage : {})
                                        }}>
                                        {a}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', flexGrow: '1'}}>
                        <img src={teams.away_team.logo}  style={styles.teamLogo} alt={teams.away_team.name + " name"} />
                        <span style={styles.teamDetails}>Conceded</span>
                        <div style={{display: 'flex', marginLeft: 'auto'}}>
                            <div style={{ display: 'flex' }}>
                                {(showPercentage ? 
                                [
                                    away_team_goal_periods.conceding_period_0_15_percentage.toFixed(1), 
                                    away_team_goal_periods.conceding_period_15_30_percentage.toFixed(1), 
                                    away_team_goal_periods.conceding_period_30_45_percentage.toFixed(1),
                                    away_team_goal_periods.conceding_period_45_60_percentage.toFixed(1), 
                                    away_team_goal_periods.conceding_period_60_75_percentage.toFixed(1), 
                                    away_team_goal_periods.conceding_period_75_90_percentage.toFixed(1) 
                                ] :
                                [
                                    away_team_goal_periods.conceding_period_0_15_count,
                                    away_team_goal_periods.conceding_period_15_30_count,
                                    away_team_goal_periods.conceding_period_30_45_count,
                                    away_team_goal_periods.conceding_period_45_60_count,
                                    away_team_goal_periods.conceding_period_60_75_count,
                                    away_team_goal_periods.conceding_period_75_90_count
                                ]).map((a, index) => {
                                    return (<span 
                                        key={`goal-period-${index}-row-2`} 
                                        style={{
                                        ...styles.periodCount, 
                                        ...styles.conceded, 
                                        ...(index === 0 ? styles.bottomFirstPeriod : {}),
                                        ...(index === 5 ? styles.bottomLastPeriod : {}),
                                        ...(showPercentage ? styles.percentage : {})
                                    }}>{a}</span>)
                                })}
                            </div>
                        </div>
                    </div>
                </MDBCol>
                <MDBCol size="12" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', padding: '5px 15px 5px 10px' }}>
                    <div style={{display: 'flex', alignItems: 'center', flexGrow: '1'}}>
                        <img src={teams.away_team.logo} style={styles.teamLogo} alt={teams.away_team.name + " name"} />
                        <span style={styles.teamDetails}>Scored</span>
                        <div style={{ display: 'flex', marginLeft: 'auto' }}>
                            <div style={{ display: 'flex' }}>
                                {(showPercentage ? [ 
                                    away_team_goal_periods.scoring_period_0_15_percentage.toFixed(1), 
                                    away_team_goal_periods.scoring_period_15_30_percentage.toFixed(1), 
                                    away_team_goal_periods.scoring_period_30_45_percentage.toFixed(1),
                                    away_team_goal_periods.scoring_period_45_60_percentage.toFixed(1), 
                                    away_team_goal_periods.scoring_period_60_75_percentage.toFixed(1), 
                                    away_team_goal_periods.scoring_period_75_90_percentage.toFixed(1) 
                                ]
                                :
                                [ 
                                    away_team_goal_periods.scoring_period_0_15_count, 
                                    away_team_goal_periods.scoring_period_15_30_count, 
                                    away_team_goal_periods.scoring_period_30_45_count,
                                    away_team_goal_periods.scoring_period_45_60_count, 
                                    away_team_goal_periods.scoring_period_60_75_count, 
                                    away_team_goal_periods.scoring_period_75_90_count 
                                ]).map((a, index) => (
                                    <span 
                                        key={`goal-period-${index}-row-3`} 
                                        style={{ 
                                            ...styles.periodCount, 
                                            ...styles.scored, 
                                            ...(index === 0 ? styles.topFirstPeriod : {}),
                                            ...(index === 5 ? styles.topLastPeriod : {}),
                                            ...(showPercentage ? styles.percentage : {})
                                        }}>
                                        {a}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', flexGrow: '1'}}>
                        <img src={teams.home_team.logo}  style={styles.teamLogo} alt={teams.home_team.name + " name"} />
                        <span style={styles.teamDetails}>Conceded</span>
                        <div style={{display: 'flex', marginLeft: 'auto'}}>
                            <div style={{ display: 'flex' }}>
                                {(showPercentage ?
                                [
                                    home_team_goal_periods.conceding_period_0_15_percentage.toFixed(1),
                                    home_team_goal_periods.conceding_period_15_30_percentage.toFixed(1),
                                    home_team_goal_periods.conceding_period_30_45_percentage.toFixed(1),
                                    home_team_goal_periods.conceding_period_45_60_percentage.toFixed(1),
                                    home_team_goal_periods.conceding_period_60_75_percentage.toFixed(1),
                                    home_team_goal_periods.conceding_period_75_90_percentage.toFixed(1)
                                ]
                                :
                                [
                                    home_team_goal_periods.conceding_period_0_15_count,
                                    home_team_goal_periods.conceding_period_15_30_count,
                                    home_team_goal_periods.conceding_period_30_45_count,
                                    home_team_goal_periods.conceding_period_45_60_count,
                                    home_team_goal_periods.conceding_period_60_75_count,
                                    home_team_goal_periods.conceding_period_75_90_count
                                ]).map((a, index) => {
                                    return (<span 
                                        key={`goal-period-${index}-row-4`} 
                                        style={{
                                        ...styles.periodCount, 
                                        ...styles.conceded, 
                                        ...(index === 0 ? styles.bottomFirstPeriod : {}),
                                        ...(index === 5 ? styles.bottomLastPeriod : {}),
                                        ...(showPercentage ? styles.percentage : {}) 
                                    }}>{a}</span>)
                                })}
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <div style={{display: 'flex', fontWeight: '500', justifyContent: 'space-evenly', fontSize: '12px', width:'192px', padding: '3px 0px 3px 0px' }}>
                            <span style={{position: 'relative', left: '-15px'}}>00'</span>
                            <span style={{position: 'relative', left: '-11px'}}>15'</span>
                            <span style={{position: 'relative', left: '-5px'}}>30'</span>
                            <span style={{position: 'relative', left: '1px'}}>45'</span>
                            <span style={{position: 'relative', left: '7px'}}>60'</span>
                            <span style={{position: 'relative', left: '13px'}}>75'</span>
                            <span style={{position: 'relative', left: '17px'}}>90'</span>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
            <Divider />
            <MDBRow style={styles.footer}>
                <MDBCol>
                    <HelpOutlineIcon sx={{fontSize: '14px', position: 'relative', top: '-2px'}} /> Goal breakdown by period for 
                    {" "}{teams.home_team.name}{" "}
                    {" "}({home_team_goal_periods.games_played} games, {home_team_goal_periods.home_games_played} home and {home_team_goal_periods.away_games_played} away) and 
                    {" "}{teams.away_team.name}{" "}
                    {" "}({away_team_goal_periods.games_played} games, {away_team_goal_periods.home_games_played} home and {away_team_goal_periods.away_games_played} away) for 
                    the{" "}{schedule.data.league.name}
                </MDBCol>
            </MDBRow>
            <Divider />
            <div style={{padding: '8px', display: 'flex', justifyContent: 'center', fontSize: '12px'}}>
                <Link className="anchor-tag no_side_padding" style={{textDecoration: 'none'}} to={`/fixture/${fixture_id}/${fixture.fixture_name}/stat-pack`}>
                    <div 
                        onClick={() => contentSetter(14, fixture_id)}
                        style={{
                            textAlign: 'center',
                            padding: '4px',
                            width: '200px'
                        }}
                    >
                        View stat pack <KeyboardArrowRightIcon />
                    </div>
                </Link>     
            </div>
        </Paper>
    )
}
