import { applyMiddleware, createStore, compose } from 'redux';
import allReducers from '../reducers'
import * as InterfaceStore from './application-state';
import thunk from 'redux-thunk';

import { 
    
    iprimary_dashboard_state, 
    
    primary_dashboard_menu, 
    
    iselected_secondary_dashboard_type,

    isecondary_dashboard_state,

    iselected_content_dashboard_type,

    icontent_dashboard_state,

    imobile_dashboard_state,

    iselected_mobile_dashboard_type

} from '../application/dashboards/dashboard_system/dashboard_interfaces';

export default function configureStore() {
    const middleware = [thunk];

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;

    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    // Load persisted state
    const persistedDate = loadPersistedDate();

    const store = createStore(
        allReducers,
        {
            ...initialState,
            site_date: persistedDate ?? null,
        },
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    // Save state to localStorage whenever it changes
    store.subscribe(() => {
        persistDate(store.getState());
    });

    return store;
}

// Utility to save state to localStorage
const persistDate = (state: ApplicationState) => {
    try {
        const date = state.site_date;
        if(date) {
            //const serializedState = JSON.stringify(state);
            localStorage.setItem('theStatManDate', date);
        }
    } catch (err) {
        console.error('Could not save state', err);
    }
};

// Utility to load state from localStorage
const loadPersistedDate = (): string | null | undefined => {
    try {
        const persistedDate = localStorage.getItem('theStatManDate');
        if (persistedDate === null) {
            return undefined;
        }
        return persistedDate;
    } catch (err) {
        console.error('Could not load state', err);
        return undefined;
    }
};

// The top-level state object

export interface ApplicationState {

    site_date?: string | null | undefined,

    primary_dashboard_state: iprimary_dashboard_state,

    secondary_dashboard_state: isecondary_dashboard_state,

    content_dashboard_state: icontent_dashboard_state,

    mobile_dashboard_state: imobile_dashboard_state,

    leagueSelectionState: InterfaceStore.LeagueSelectionState

}

export const initialState = {

    site_date: null,

    leagueSelectionState: 
    { 

        isLoading: false,

        error: '',

        league_selection_response: []

    } as InterfaceStore.LeagueSelectionState,

    primary_dashboard_state: primary_dashboard_menu,

    secondary_dashboard_state: 
    { 
        
        selectedDashboardType: iselected_secondary_dashboard_type.Home,

        selectedLeagueID: null
    
    } as isecondary_dashboard_state,

    content_dashboard_state: 
    {

        selectedDashboardType: null,

        selectedLeagueID: null,

    } as icontent_dashboard_state,

    mobile_dashboard_state: 
    {

        selectedDashboardType: iselected_mobile_dashboard_type.LiveScores,

        selectedContentID: null

    } as imobile_dashboard_state

} as ApplicationState;

export interface AppThunkAction<TAction> {

    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;

}