import React, { useState } from "react"
import { Paper, styled } from "@mui/material";
import Divider, { dividerClasses } from '@mui/material/Divider';
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { BiChevronDown, BiChevronUp, BiMinus } from "react-icons/bi";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { GetStatGradient } from "../../../common/helpers/rating_helper";
import { useGoalsPack } from "../../../application/statpack/statPackHook";
import { DateController } from "../../../application/shared/date/button_date_picker";

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

const styles = {
    footer: {
        fontSize: '12px',
        paddingTop: '5px',
        paddingBottom: '5px',
        color: '#737373'
    }
}

export default function GoalsPack({ date, switch_site_date }) {

    const [goalsPackResponse, setGoalsPack] = useState({ isLoading: false, data: [] })

    useGoalsPack(date, setGoalsPack);

    if ((!goalsPackResponse.data && goalsPackResponse.isLoading) || (!goalsPackResponse.data && !goalsPackResponse.isLoading)) {
        return <></>
    }

    if(goalsPackResponse.data.length < 1) {
        return <></>
    }

    return (
        <Paper elevation={1} sx={{[`& .${dividerClasses.root}`]: { mx: 0, height: '0.5px', opacity: '0.1' }}} className='dashboard_widget br-15'>
            <div style={{display: 'flex'}}>
                    <label className="dashboard_widget_controls">
                        <span>Both Teams To Score</span>
                    </label>
                    <span>
                        <DateController selected_date={date} set_date={switch_site_date} />
                    </span>
                </div>
            <Divider className="mt-1" variant="middle" />
            <MDBRow style={styles.footer}>
                <MDBCol>
                    <HelpOutlineIcon sx={{ fontSize: '14px', position: 'relative', top: '-2px' }} />
                    {" "}Fixtures are ordered to indicate the likelihood of both teams scoring, based on results from the current competition. Provided percentages are for home, away and overall.
                </MDBCol>
            </MDBRow>
            <Divider className="mt-1" variant="middle" />
                <div style={{ paddingBottom: "15px" }}>
                {
                    goalsPackResponse.data.map((fixture, index) => {
                        return (
                            <React.Fragment key={"goals_pack_" + fixture.fixtureId}>
                                <TeamRow team={fixture.homeTeam} opponent_position={fixture.awayTeam.position} />
                                <TeamRow team={fixture.awayTeam} opponent_position={fixture.homeTeam.position} />
                                <Divider />
                            </React.Fragment>
                        )
                    })
                }    
            </div>
        </Paper>
    )
}

function TeamRow({team, opponent_position}) {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', padding:'5px 5px 5px 10px', fontSize: '13px' }}>
            <div style={{display: 'flex', gap: '5px', padding: '10px 0px 10px 10px' }}>
                <span style={{display: 'flex', alignItems: 'center', marginRight: '6px'}}>
                    { team.position === opponent_position ? 
                        <BiMinus size={22} color="grey" /> : 
                            team.position < opponent_position ? 
                                <BiChevronUp size={22} color="green" /> : 
                                <BiChevronDown size={22} color="red" /> 
                    }
                </span>
                <span style={{display: 'flex', alignItems: 'center', marginRight: '6px'}}>
                    <img src={team.teamLogo} alt={ team.teamName + " logo"} style={{width: '22px', height: '22px'}} />
                </span>
                <span style={{ display: 'flex', alignItems: 'center', maxWidth: '165px' }}>
                    <label className="elipsis_text_wrap" style={{ fontWeight: '400', fontSize: '14px' }}>{team.teamName}</label>
                </span>  
            </div>          
            <div style={{marginLeft: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2px' }}>
                <BootstrapTooltip title="Home" placement="bottom">
                    <div className={GetStatGradient(team.home_percentage)} style={{ border: '1px solid white', padding: '6px', color: 'white', fontWeight: 'bold', width: '45px', textAlign: 'center'}}>{team.home_percentage}%</div>            
                </BootstrapTooltip>
                <BootstrapTooltip title="Away" placement="bottom">
                    <div className={GetStatGradient(team.away_percentage)} style={{border: '1px solid white', padding: '6px', color: 'white', fontWeight: 'bold', width: '45px', textAlign: 'center'}}>{team.away_percentage}%</div>               
                </BootstrapTooltip>
                <BootstrapTooltip title="Overall" placement="bottom">
                    <div className={GetStatGradient(team.percentage)} style={{border: '1px solid white', padding: '6px', color: 'white', fontWeight: 'bold', width: '45px', textAlign: 'center'}}>{team.percentage}%</div>         
                </BootstrapTooltip>
            </div>
        </div>
    )
}