import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import FoulsPack from "../../../../application/statpack/fouls_pack";
import FoulsDrawnPack from "../../../../application/statpack/fouls_drawn_pack";
import ShotsOnTargetPack from "../../../../application/statpack/shots_on_target_pack";
import { useState } from "react";
import SavesPack from "../../../../application/statpack/saves_pack";
import TotalShotsPack from "../../../../application/statpack/shots_pack";
import TotalTacklesPack from "../../../../application/statpack/tackles_pack";
import PlayerGoalsPack from "../../../../application/statpack/player_goals_pack";
import AssistsPack from "../../../../application/statpack/assists_pack";
import { FormControl, MenuItem, Select } from "@mui/material";

const styles = {
    statContainer: {
        flexShrink: 0,
        flexGrow: 1,
        borderRadius: "5px",
        padding: "5px",
        display: "flex",
        justifyContent: "space-evenly",
        scrollSnapAlign: "start",
        cursor: 'pointer'
    }
};

export default function PlayerStatisticsDashboard({ site_date, contentSetter, switch_site_date, isMobile }) {
    const [statType, setStatType] = useState('7');

    const selectStatType = (event) => {
        if (setStatType !== event.target.value) {
            setStatType(event.target.value);
        }
    };

    return (
        <MDBRow>
            <MDBCol className="no_side_padding" size="12">
                <FormControl fullWidth className="mb-2" style={{backgroundColor: 'white'}}>
                    <Select
                        size="small"
                        value={statType}
                        label="Statistic Type"
                        onChange={selectStatType}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ "& .MuiInputLabel-root": { display: "none" } }}
                    >
                    {
                            [
                            { statisticType: '1', statisticName: "Fouls" }, 
                            { statisticType: '2', statisticName: "Fouls Drawn" }, 
                            { statisticType: '3', statisticName: "Shots On Target" }, 
                            { statisticType: '4', statisticName: "Saves" }, 
                            { statisticType: '5', statisticName: "Shots" },
                            { statisticType: '6', statisticName: "Tackles" },
                            { statisticType: '7', statisticName: "Goals" },
                            { statisticType: '8', statisticName: "Assists" }
                        ].map((statistic, index) => {
                            return (<MenuItem key={"player_statistic_select_option_" + statistic.statisticType} value={statistic.statisticType}>{statistic.statisticName}</MenuItem>)
                        })
                    }
                    </Select>
                </FormControl>
                {statType === '1' && <FoulsPack isMobile={isMobile} switch_site_date={switch_site_date} date={site_date} />}
                {statType === '2' && <FoulsDrawnPack isMobile={isMobile} switch_site_date={switch_site_date} date={site_date} />}
                {statType === '3' && <ShotsOnTargetPack isMobile={isMobile} switch_site_date={switch_site_date} date={site_date} />}
                {statType === '4' && <SavesPack isMobile={isMobile} switch_site_date={switch_site_date} date={site_date} />}
                {statType === '5' && <TotalShotsPack isMobile={isMobile} switch_site_date={switch_site_date} date={site_date} />}
                {statType === '6' && <TotalTacklesPack isMobile={isMobile} switch_site_date={switch_site_date} date={site_date} />}
                {statType === '7' && <PlayerGoalsPack isMobile={isMobile} switch_site_date={switch_site_date} date={site_date} />}
                {statType === '8' && <AssistsPack isMobile={isMobile} switch_site_date={switch_site_date} date={site_date} />}
            </MDBCol>
        </MDBRow>
    )
}