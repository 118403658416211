import { useEffect } from 'react';
import axios from 'axios';
import configuration from '../../config.json';

export const useCommentary = (setter) => {
  const controller = new AbortController();
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if(isMounted) {
        try {
          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/commentary`, { signal: controller.signal });
  
            var result = null;
            if (response.status === 200) {
              result = response.data;
            }
        } 
        catch (error) {
          setter(null);
        }
      }
    };

    fetchData();

    // Cleanup function to set isMounted to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [setter]);
};

export const useFixtureStatPack = (fixture_id, setter) => {
  const controller = new AbortController();
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id === null ? -1 : fixture_id}/statpack`, { signal: controller.signal });
        
        if(isMounted) {
          if (response.status === 200) {
            setter({ isLoading: false, data: response.data, fixture_id: fixture_id });
            return;
          }

          setter({ isLoading: false, data: null, fixture_id: fixture_id });
        }
      } 
      catch (error) {
        if(isMounted) {
          setter({ isLoading: false, data: null, fixture_id: fixture_id });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fixture_id]);
};


export const useFixtureDashboard = (fixture_id, setter) => {
  const controller = new AbortController();
    useEffect(() => {
      let isMounted = true;
  
      const fetchData = async () => {
        try {
          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id}`, { signal: controller.signal });
          
          if(isMounted) {
            if (response.status === 200) {
              setter({ isLoading: false, data: response.data, fixture_id: fixture_id });
              return;
            }
  
            setter({ isLoading: false, data: null, fixture_id: fixture_id });
          }
        } 
        catch (error) {
          if(isMounted) {
            setter({ isLoading: false, data: null, fixture_id: fixture_id });
          }
        }
      };
  
      fetchData();
  
      return () => {
        isMounted = false;
      };
    }, [fixture_id]);
  };

  export const useLineups = (fixture_id, setter) => {
    const controller = new AbortController();
    useEffect(() => {
      let isMounted = true;

      const fetchData = async () => {
        try {
          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id}/lineups`, { signal: controller.signal });
          
          if(isMounted) {
            if (response.status === 200) {
              setter({ isLoading: false, data: response.data });
              return;
            }
  
            setter({ isLoading: false, data: null });
          }
        } 
        catch (error) {
          if(isMounted) {
            setter({ isLoading: false, data: null });
          }
        }
      };
  
      fetchData();
  
      // Cleanup function to set isMounted to false when the component is unmounted
      return () => {
        isMounted = false;
      };
    }, [fixture_id]);
  };

  export const useMatchOfficials = (fixture_id, setter) => {
    const controller = new AbortController();
    useEffect(() => {
      let isMounted = true;
  
      const fetchData = async () => {
        try {
          var result = { isLoading: false, data: null };

          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id}/matchofficials`, { signal: controller.signal });
          
          if(isMounted) {
            if (response.status === 200) {
              setter({ isLoading: false, data: response.data });
              return;
            }
  
            setter({ isLoading: false, data: null });
          }
        } 
        catch (error) {
          if(isMounted) {
            setter({ isLoading: false, data: null });
          }
        }
      };
  
      fetchData();
  
      // Cleanup function to set isMounted to false when the component is unmounted
      return () => {
        isMounted = false;
      };
    }, [fixture_id, setter]);
  };

  export const useManOfTheMatch = (fixture_id, setter) => {
    const controller = new AbortController();
    useEffect(() => {
      let isMounted = true;
  
      (async () => {
        try {
          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id}/manofthematch`, { signal: controller.signal });
          
          if(isMounted) {
            if (response.status === 200) {
              setter({ isLoading: false, data: response.data });
              return;
            }
  
            setter({ isLoading: false, data: null });
          }
        } 
        catch (error) {
          if (isMounted) {
           setter({ isLoading: false, data: null });
          }
        }
      })();
  
      return () => {
        isMounted = false;
      };
    }, [fixture_id, setter]);
  };

  export const useFixtureGoalResults = (fixture_id, setter) => {
    const controller = new AbortController();
    useEffect(() => {
      let isMounted = true;
  
      (async () => {
        try {
          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id}/goalresults`, { signal: controller.signal });
          
          if(isMounted) {
            if (response.status === 200) {
              setter({ isLoading: false, data: response.data });
              return;
            }
  
            setter({ isLoading: false, data: null });
          }
        } 
        catch (error) {
          if (isMounted) {
           setter({ isLoading: false, data: null });
          }
        }
      })();
  
      // Cleanup function to set isMounted to false when the component is unmounted
      return () => {
        isMounted = false;
      };
    }, [fixture_id, setter]);
  };

  export const useFixtureGoalPeriods = (fixture_id, setter) => {
    const controller = new AbortController();
    useEffect(() => {
      let isMounted = true;
  
      (async () => {
        try {

          var result = { isLoading: false, data: null };

          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id}/goalperiods`, { signal: controller.signal });
          
          if(isMounted) {
            if (response.status === 200) {
              setter({ isLoading: false, data: response.data });
              return;
            }
  
            setter({ isLoading: false, data: null });
          }
        } 
        catch (error) {
          if (isMounted) {
           setter({ isLoading: false, data: null });
          }
        }
      })();
  
      // Cleanup function to set isMounted to false when the component is unmounted
      return () => {
        isMounted = false;
      };
    }, [fixture_id, setter]);
  };

  export const useFixtureFormGuide = (fixture_id, setter) => {
    const controller = new AbortController();
    useEffect(() => {
      let isMounted = true;
  
      (async () => {
        try {
          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id}/form`, { signal: controller.signal });
          
          if(isMounted) {
            if (response.status === 200) {
              setter({ isLoading: false, data: response.data });
              return;
            }
  
            setter({ isLoading: false, data: null });
          }
        } 
        catch (error) {
          if (isMounted) {
           setter({ isLoading: false, data: null });
          }
        }
      })();
  
      // Cleanup function to set isMounted to false when the component is unmounted
      return () => {
        isMounted = false;
      };
    }, [fixture_id, setter]);
  };

  export const useFixtureTvStations = (fixtureId, setter) => {
    const controller = new AbortController();
    useEffect(() => {
      let isMounted = true;
  
      (async () => {
        if(isMounted) {
          try {
            const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixtureId}/tv-stations`, { signal: controller.signal });
    
            if(isMounted) {
              if (response.status === 200) {
                setter({ isLoading: false, data: response.data });
                return;
              }
    
              setter({ isLoading: false, data: null });
            }
          } 
          catch (error) {
            if(isMounted) {
              setter({ isLoading: false, data: null });
            }
          }
        }
      })();
  
      return () => {
        isMounted = false;
      };
    }, [fixtureId])
  }

  export const useFixtureHighlights = (fixtureId, setter) => {
    const controller = new AbortController();
    useEffect(() => {
      let isMounted = true;
  
      (async () => {
        if(isMounted) {
          try {
            const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixtureId}/highlights`, { signal: controller.signal });
    
            if(isMounted) {
              if (response.status === 200) {
                setter({ isLoading: false, data: response.data });
                return;
              }
    
              setter({ isLoading: false, data: null });
            }
          } 
          catch (error) {
            if(isMounted) {
              setter({ isLoading: false, data: null });
            }
          }
        }
      })();
  
      return () => {
        isMounted = false;
      };
    }, [fixtureId])
  }

export const useResults = (date, league_id, setter) => {
  const controller = new AbortController();
  useEffect(() => {
    let isMounted = true;

    (async () => {
      if(isMounted) {
        try {
          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/results?fixtureDate=${date !== null && date !== undefined && date !== '' ? date : ""}&leagueId=` + (league_id == null ? "" : league_id), { signal: controller.signal });
  
          if(isMounted) {
            if (response.status === 200) {
              setter({ isLoading: false, data: response.data });
              return;
            }
  
            setter({ isLoading: false, data: null });
          }
        } 
        catch (error) {
          if(isMounted) {
            setter({ isLoading: false, data: null });
          }
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [date, league_id, setter])
}

export const useFixtureSchedule = (fixture_id, setter) => {
  const controller = new AbortController();
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id === null ? -1 : fixture_id}/schedule`, { signal: controller.signal });
        
        if(isMounted) {
          if (response.status === 200) {
            setter({ isLoading: false, data: response.data });
            return;
          }

          setter({ isLoading: false, data: null });
        }
      } 
      catch (error) {
        if(isMounted) {
          setter({ isLoading: false, data: null });
        }
      }
    };

    fetchData();

    // Cleanup function to set isMounted to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [fixture_id, setter]);
};

export const useFixturePlayerStatistics = (fixture_id, setter) => {
  const controller = new AbortController();
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id === null ? -1 : fixture_id}/playerstatistics`, { signal: controller.signal });
        
        if(isMounted) {
          if (response.status === 200) {
            setter({ isLoading: false, data: response.data });
            return;
          }

          setter({ isLoading: false, data: null });
        }
      } 
      catch (error) {
        if(isMounted) {
          setter({ isLoading: false, data: null });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fixture_id, setter]);
};