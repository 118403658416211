import React, { useCallback, useRef, useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import ErrorBoundary from "../error/error_boundary";
import { IoIosFootball } from "react-icons/io";
import { FaAmbulance, FaFlagCheckered, FaTv } from "react-icons/fa";
import { GiReturnArrow } from "react-icons/gi";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { BiFootball, BiTargetLock } from "react-icons/bi";
import { LuFlagTriangleRight } from "react-icons/lu";
import { IoFootballOutline } from "react-icons/io5";
import { Box, Grid, Paper } from "@mui/material";
import { useIsVisible } from "../shared/hooks/isVisibleHook";
import Divider, { dividerClasses } from '@mui/material/Divider';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const styles = {
    playerName: {
        fontWeight: '500',
        fontSize: '16px'
    },
    description: {
        color: 'grey',
        fontSize: '16px'
    },
    icon: {
        
    },
    card: {
        width: '15px',
        height: '21px',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
    }
}

function BuildEvent({ event }) {
    const { event_type, sub_type_id, is_home, minute, extra_minute, player_name, assisting_player_name, description, type_name, result, additional_information } = event;

    const getEventDetails = () => {
        switch (event_type) {
            case "substitution":
                case "substitution":
                return {
                    icon:  <SwapHorizIcon style={{...styles.icon, fontSize: '26px', color: '#374df5' }} />,
                    details: (
                        <>
                            {is_home ? 
                            <>
                                <span style={styles.playerName}>
                                    <span style={{color: '#1f8f1f'}}>{player_name}</span>
                                </span>
                                <span style={styles.description}>
                                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', color: '#dd3c23' }}>
                                        {assisting_player_name} {sub_type_id === 1524 ? <FaAmbulance style={styles.icon} fill="#dd3c23" size={22} /> : <></>}
                                    </span>
                                </span>
                            </> : 
                            <>
                                <span style={styles.playerName}>
                                    <span style={{color: '#1f8f1f'}}>{player_name}</span>
                                </span>
                                <span style={styles.description}>
                                    {
                                        <>
                                            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', color: '#dd3c23' }}>
                                                {sub_type_id === 1524 ? <FaAmbulance style={styles.icon} fill="#dd3c23" size={16} /> : <></>} {assisting_player_name}
                                            </span>
                                        </>
                                    }
                                </span>
                            </>
                            }
                        </>
                    )
                };
            case "VAR_CARD":
                {
                    return {
                        icon: <FaTv style={styles.icon} size={25} />,
                        details: (
                            <>
                                <span style={styles.playerName}>{player_name}</span>
                                <span style={styles.description}>{additional_information === "" || additional_information === null ? <></> : additional_information}</span>
                            </>
                        )
                    };
                }
            case "yellowredcard":
            case "redcard":
                return {
                    icon: <div style={{ ...styles.card, backgroundColor: '#dd3c23' }}></div>,
                    details: (
                        <>
                            <span style={styles.playerName}>{player_name == null ? <></> : player_name}</span>
                            <span style={styles.description}>{description === "" || description === null ? <></> : description}</span>
                        </>
                    )
                };
            case "yellowcard":
                return {
                    icon: <div style={{...styles.card, backgroundColor: '#d3ad02' }}></div>,
                    details: (
                        <>
                            <span style={styles.playerName}>{player_name == null || player_name == "" ? "Yellow Card" : player_name}</span>
                            <span style={styles.description}>{description === "" || description === null ? <></> : description}</span>
                        </>
                    )
                };
            case "penalty":
                return {
                    icon: <BiTargetLock style={styles.icon} color="green" size={25} />,
                    details: (
                        <>
                            <span style={styles.playerName}>{is_home ? 
                                    <><span style={{fontWeight: '500', marginRight: '5px'}}>{result}</span><span>{player_name == null ? "Penalty" : player_name}</span></> : 
                                    <><span>{player_name == null ? "Penalty" : player_name}</span><span style={{fontWeight: '500', marginLeft: '5px'}}>{result}</span></>
                                }</span>
                            <span style={styles.description}>{type_name}</span>
                        </>
                    )
                };
            case "owngoal":
                return {
                    icon: <IoIosFootball style={styles.icon} fill="red" size={22} />,
                    details: (
                        <>
                            <span style={styles.playerName}>
                                {is_home ? 
                                    <><span style={{fontWeight: '500', marginRight: '5px'}}>{result}</span><span>{player_name == null ? "Own Goal" : player_name}</span></> : 
                                    <><span>{player_name == null ? "Own Goal" : player_name}</span><span style={{fontWeight: '500', marginLeft: '5px'}}>{result}</span></>
                                }
                            </span>
                            <span style={styles.description}>{assisting_player_name}</span>
                        </>
                    )
                };
            case "goal":
                return {
                    icon: <IoFootballOutline style={styles.icon} color="green" size={22} />,
                    details: (
                        <>
                            <div style={styles.playerName}>
                            {is_home ? 
                                <>
                                    <span style={{...styles.player_name, marginRight: '5px'}}>{result}</span><span>{player_name == null ? "Goal" : player_name}</span>
                                </> : 
                                <>
                                    <span>{player_name == null ? "Goal" : player_name}</span><span style={{fontWeight: '500', marginLeft: '5px'}}>{result}</span>
                                </>
                            }
                            </div>
                            {
                                assisting_player_name != null ?
                                <span style={styles.description}>Assist: <span style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{assisting_player_name}</span></span> :
                                <></>
                            }
                        </>
                    )
                };
            case "VAR":
                return {
                    icon: <FaTv style={styles.icon} color="black" size={22} />,
                    details: (
                        <>
                            <span style={styles.playerName}>{additional_information}</span>
                            <span style={styles.description}>{player_name}</span>
                        </>
                    )
                };
            case "missed_penalty":
                return {
                    icon: <GiReturnArrow style={styles.icon} color="red" size={22} />,
                    details: (
                        <>
                            <span style={styles.playerName}>{additional_information}</span>
                            <span style={styles.description}>{player_name}</span>
                        </>
                    )
                };
            case "shot-off-target":
                {
                    return {
                        icon: <BiFootball style={styles.icon} color="red" size={22} />,
                        details: (
                            <>
                                <span style={styles.playerName}>{additional_information}</span>
                                <span style={styles.description}>{player_name}</span>
                            </>
                        )
                    };
                };
            case "shot-on-target":
                {
                    return {
                        icon: <BiFootball style={styles.icon} color="green" size={22} />,
                        details: (
                            <>
                                <span style={styles.playerName}>{additional_information}</span>
                                <span style={styles.description}>{player_name}</span>
                            </>
                        )
                    };
                };
            case "offside":
                {
                    return {
                        icon: <FaFlagCheckered style={styles.icon} color="black" size={22} />,
                        details: (
                            <>
                                <span style={styles.playerName}>{additional_information}</span>
                                <span style={styles.description}>{player_name}</span>
                            </>
                        )
                    };
                };
            case "corner":
                {
                    return {
                        icon: <LuFlagTriangleRight style={styles.icon} color="black" size={22} />,
                        details: (
                            <>
                                <span style={styles.playerName}>{additional_information}</span>
                                <span style={styles.description}>{player_name}</span>
                            </>
                        )
                    };
                };
            default:
                return {};
        }
    };

    const { icon, details } = getEventDetails();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: is_home ? 'row' : 'row-reverse',
                borderRadius: 1,
                lineHeight: '18px',
                gap: '5px',
                '& svg': {
                    m: 0,
                },
                [`& .${dividerClasses.root}`]: {
                    mx: 0.5,
                },
            }}
            >
                <div style={{width: 'auto', minWidth: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span>
                        {minute}'{extra_minute !== null ? <sup className="extra-minute">+{extra_minute}</sup> : null}
                    </span>
                </div>
                <div style={{margin: 0, width: '26px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <span>
                        {icon}
                    </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: is_home ? 'left' : 'right' }}>
                    {details}
                </div>  
        </Box>
    );
}

function BuildEvents({ events }) {
    if (events.length < 1) return null;

    return events
        .map(event => (
            <MDBRow key={`fixture_event_${event.id}`} style={{margin: '15px 0px'}} className="no_side_padding">
                <MDBCol className="no_side_padding">
                    <BuildEvent event={event} />
                </MDBCol>
            </MDBRow>
        ));
}

function GetAddedMinutesDisplay({ period }) {
    return period.time_added == null ? null : (
        <div style={{fontSize: '10px', textAlign: 'center', marginTop: '5px', marginBottom: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {`+ ${period.time_added} Minutes Added`}
        </div>
    );
}

function GetSeparatorText({ is_current_period, period, fixture }) {
    if (is_current_period && fixture.fixture_state.state === "FT") {
        return `FT ${fixture.fixture_state.home_team_score}-${fixture.fixture_state.away_team_score}`;
    }

    if (is_current_period) {
        return `${period.type_name} ${fixture.fixture_state.home_team_score}-${fixture.fixture_state.away_team_score}`;
    }

    switch (period.description) {
        case "1st-half":
            return `HT ${fixture.fixture_state.first_half_home_score}-${fixture.fixture_state.first_half_away_score}`;
        case "2nd-half":
            return `FT ${fixture.fixture_state.second_half_home_score}-${fixture.fixture_state.second_half_away_score}`;
        default:
            return `${period.type_name} ${fixture.fixture_state.home_team_score}-${fixture.fixture_state.away_team_score}`;
    }
}

export default function FixtureEvents({ events, fixture, periods }) {
    const componentMounted = useRef(true);
    const [isVisible, setIsVisible] = useState(false);
    useIsVisible(isVisible, setIsVisible, componentMounted);
    const [periodID, setPeriodID] = useState(-1);

    const handlePeriodChange = useCallback((event, newPeriodID) => {
        if (newPeriodID !== null) {
            setPeriodID(newPeriodID);
        }
    }, []);

    const benchEvents = GetPeriodEvents(events, true, -1);
                    
    return (
        <ErrorBoundary>
            <Paper elevation={1} className={`mb-2 br-15 p-2 widget_fade_in ${isVisible ? 'fade-in' : ''}`}>
                <MDBCol size='6' style={{ display: 'flex', alignItems: 'center' }}>
                    <ToggleButtonGroup
                        className="fixture_period_toggle_group"
                        size='small'
                        value={periodID}
                        exclusive
                        onChange={handlePeriodChange}
                        aria-label="text alignment"
                    >
                        <ToggleButton color="primary" key="event_toggle_group_all" value={-1} aria-label="left aligned">All</ToggleButton>
                            {periods.sort((a, b) => a.sort_order - b.sort_order)
                                .filter(a => a.description !== 'penalties')
                                .map(period => (
                                    <ToggleButton color="primary" key={period.id} value={period.id} aria-label="left aligned">
                                        {period.short_name}
                                    </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                </MDBCol>
                {periods
                    .sort((a, b) => b.sort_order - a.sort_order)
                    .filter(a => (periodID === -1 ? true : a.id === periodID) && a.description !== 'penalties')
                    .map((period, index) => {
                        const periodEvents = GetPeriodEvents(events, false, period.id);

                        if(periodID !== -1 && periodEvents.length < 1) {
                            return (<Divider style={{marginTop: '10px', marginBottom: '10px'}}>
                                No Events
                            </Divider>)
                        }

                        return periodEvents && periodEvents.length > 0 && (
                        <div key={`period_events_${period.id}`} style={{ padding: '3px' }}>
                            <div className="event_period_separator mt-2 mb-3">
                            <Divider>
                                {GetSeparatorText({ is_current_period: index === 0, period, fixture })}
                            </Divider>
                            {GetAddedMinutesDisplay({ period })}
                            </div>
                            {BuildEvents({ events: periodEvents })}
                        </div>
                        );
                    })
                }
                {benchEvents && benchEvents.length > 0 && <React.Fragment key={`period_events_on_bench`}>
                    <div className="event_period_separator">
                        <label>Bench</label>
                    </div>
                    {BuildEvents({ events: benchEvents })}
                </React.Fragment>}
            </Paper>
    </ErrorBoundary>
  );
}

function GetPeriodEvents(events, isBench, periodId) {
    return events
        .filter(a => a.period_id === periodId && a.is_bench === isBench)
        .sort((a, b) => {
            const aTotalMinutes = a.minute + (a.extra_minute ?? 0);
            const bTotalMinutes = b.minute + (b.extra_minute ?? 0);

            if (aTotalMinutes !== bTotalMinutes) {
                return bTotalMinutes - aTotalMinutes;
            } else {
                return b.id - a.id;
            }
        }
    );
}