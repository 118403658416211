import React, { useState } from 'react';
import { MDBRow, MDBCol } from "mdbreact";
import '../css/fixture_dashboard.css';
import '../css/masonary.css';
import { useFixtureDashboard, useFixtureSchedule, useLineups } from '../../fixtures/fixtureHook';
import FixtureLineups from '../../fixtures/lineups/fixture_lineups';
import FixturePenalties from '../../fixtures/fixture_penalties';
import FixtureStatistics from '../../fixtures/fixture_statistics';
import FixtureDetails from '../../fixtures/fixture_details';
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import MatchOfficials from '../../fixtures/match_officials';
import { useLiveFixtureSubscription } from '../../infrastructure/subscriptions/fixtureSubscriptionHook';
import FixtureCommentary from '../../fixtures/fixture_commentary';
import FixtureSchedule from '../../fixtures/fixture_schedule';
import errorImg from '../../../images/opps_something_went_wrong.jpg';
import FixtureHelmet from '../../helmets/fixture_helmet';
import SwipeableViews from 'react-swipeable-views';
import FixtureEvents from '../../fixtures/fixture_events';
import FixtureTVStations from '../../fixtures/fixture_tv_stations';
import FixtureTopRated from '../../fixtures/fixture_top_rated';
import FixtureHighlights from '../../fixtures/fixture_highlights';
import FixtureFormGuide from '../../fixtures/fixture_form_guide';
import FixtureManOfTheMatch from '../../fixtures/fixture_man_of_the_match';
import FixtureGoalPeriods from '../../fixtures/fixture_goal_periods';
import FixtureGoalResults from '../../fixtures/fixture_goal_results';

const FixtureDashboard = ({ fixture_id, contentSetter, isMobile, site_date }) => {
    const [fixtureDashboardResponse, setFixtureDashboard] = useState({
        isLoading: true,
        data: null,
        fixture_id,
        last_updated: null
    });

    const [schedule, setFixtureSchedule] = useState({ data: null, isLoading: true });
    const [fixtureLineups, setFixtureLineups] = useState({ isLoading: true, data: null });

    const [index, setIndex] = useState(0);

    useFixtureDashboard(fixture_id, setFixtureDashboard);
    useLiveFixtureSubscription(fixture_id, setFixtureDashboard);
    useFixtureSchedule(fixture_id, setFixtureSchedule);
    useLineups(fixture_id, setFixtureLineups);

    if(fixtureDashboardResponse.data === null && !fixtureDashboardResponse.isLoading) {
        return (
        <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <MDBRow>
                <MDBCol size='12'>
                    <img src={errorImg} alt='no available fixture' style={{height: '300px', width: '275px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} />
                </MDBCol>
                <MDBCol size='12'>
                    <Typography style={{textAlign: 'center'}} variant="h5">An error occured</Typography>
                    <Typography style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} variant="caption">The fixture you where looking for could not be found</Typography>
                </MDBCol>
            </MDBRow>
        </Paper>)
    }

    if (!fixtureDashboardResponse.data ||
        fixtureDashboardResponse.isLoading ||
        fixture_id !== fixtureDashboardResponse.fixture_id) 
    {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Box sx={{ width: '100%', height: '400px', padding: '40px' }}>
                    <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
                </Box>
            </div>
        );
    }

    const 
    {
        data: {
            events,
            periods,
            comments,
            league,
            fixture,
            period_statistics: periodStats,
            fixture_statistics: stats,
            last_updated
        },
    } = fixtureDashboardResponse;

    let teams = {
        home_team: fixture.home_team, 
        away_team: fixture.away_team,  
    }

    var tabPanels = 
    { 
        0: fixture.fixture_state.state === "NS" ? "Preview" : 'Match'
    };

    if(fixtureLineups.data !== null && !fixtureLineups.isLoading) {
        tabPanels[1] = 'Lineups';
    }

    if(stats.length > 0) {
        tabPanels[2] = 'Statistics';
    }

    if(comments.length > 0) {
        tabPanels[3] = 'Commentary';
    }

    const renderCommentary = (key) => {
        if (!tabPanels[3] || comments.length < 1) {
            return <React.Fragment key={key} />;
        }

        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                <FixtureCommentary comments={comments} />
            </MDBCol>
        );
    };

    const renderStatistics = (key) => {
        if (!tabPanels[2] || (periods.length < 1 && periodStats.length < 1)) {
            return <React.Fragment key={key} />;
        }

        return (
            <MDBRow key={key}>
                <MDBCol className="no_side_padding" size="12">
                    <FixtureStatistics
                        periods={periods}
                        fixture_id={fixture_id}
                        fixture_statistics={stats}
                    />
                </MDBCol>
            </MDBRow>
        );
    };

    const renderLineups = (key) => {
        if (!tabPanels[1]) {
            return <React.Fragment key={key} />
        }

        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                <FixtureLineups
                    fixture_id={fixture_id}
                    fixtureLineups={fixtureLineups}
                    teams={teams}
                    contentSetter={contentSetter}
                />
            </MDBCol>
        );
    };

    const renderTopRated = () => {
        if (!tabPanels[1]) {
            return <></>
        }
        return (
            <FixtureTopRated 
                fixture_id={fixture_id}
                teams={teams}
                fixtureLineups={fixtureLineups}
                setIndex={setIndex}
            />
        )
    }

    const renderManOfTheMatch = () => {
        if (fixture.fixture_state.state !== "FT") {
            return <></>
        }
        return (
            <FixtureManOfTheMatch fixture_id={fixture_id} />
        )
    }

    const renderFixtureGoalPeriods = () => {
        return (
            <FixtureGoalPeriods 
                fixture_id={fixture_id}
                fixture={fixture}
                teams={teams}
                contentSetter={contentSetter}
                schedule={schedule}
            />
        )
    }

    const renderFixtureGoalResults = () => {
        return (
            <FixtureGoalResults 
                fixture_id={fixture_id}
                fixture={fixture}
                teams={teams}
                contentSetter={contentSetter}
                schedule={schedule}
            />
        )
    }

    const renderFormGuide = () => {
        return (
            <FixtureFormGuide 
                fixture_id={fixture_id}
                teams={teams}
                contentSetter={contentSetter}
            />
        )
    }

    const renderMatchDetails = (key) => {

        const penaltyPeriod = periods.find((period) => period.description === 'penalties');

        var filteredEvents = events?.filter((event, index) => { return event.section === "event"}) ?? [];

        return (
            <React.Fragment key={key}>
                <MDBRow className='no_side_padding'>
                    <MDBCol style={{padding: '0px 6px 0px 0px'}} size='6'>
                        <FixtureHighlights fixtureId={fixture_id} fixtureName={fixture.name} />
                        {
                            penaltyPeriod && <FixturePenalties
                                events={events}
                                period={penaltyPeriod}
                                teams={teams}
                            />
                        }
                        {
                            filteredEvents && filteredEvents.length > 0 && <FixtureEvents
                                events={filteredEvents}
                                periods={periods}
                                fixture={fixture}
                            />
                        }
                        <FixtureTVStations fixtureId={fixture_id} countryId={league.country_id} /> 
                    </MDBCol>
                    <MDBCol style={{padding: '0px 0px 0px 6px'}} size='6'>
                        {renderManOfTheMatch()}
                        {renderTopRated()}
                        {renderFormGuide()}
                        {renderFixtureGoalPeriods()}
                        {renderFixtureGoalResults()} 
                    </MDBCol>
                </MDBRow>
                <MDBRow className='no_side_padding'>
                    <MDBCol className='no_side_padding' size="12">
                        <FixtureSchedule fixture_id={fixture_id} teams={teams} schedule={schedule} />
                    </MDBCol>
                </MDBRow>
            </React.Fragment>
        )
    };

    const renderTabItem = (item, index) => {
        let key = `${item}_${index}_tab`;
        switch(item)
        {
            case "Commentary":
                {
                    return renderCommentary(key);
                }
            case "Lineups":
                {
                    return renderLineups(key);
                }
            case "Statistics":
                {
                    return renderStatistics(key);
                }
            case "Preview":
            case "Match":
                {
                    return renderMatchDetails(key);
                }
            default:
                {
                    return null;
                }
        }
    };

    return (
        <>
            <FixtureHelmet fixture={fixture} schedule={schedule} teams={teams} league={league} fixture_id={fixture_id} />
            <MDBRow className='no_side_padding'>
                <MDBCol className='no_side_padding' size="12">
                    <FixtureDetails
                        goalEvents={events.filter((event) => {return event.type_id === 14 || event.type_id === 16 || event.type_id === 15})}
                        contentSetter={contentSetter}
                        fixture_id={fixture_id}
                        detailsResponse={fixtureDashboardResponse}
                        last_updated={last_updated}
                        schedule={schedule}
                        tabPanels={tabPanels}
                        index={index}
                        setIndex={setIndex}
                    />
                </MDBCol>
                {Object.keys(tabPanels).length > 0 && 
                    <MDBRow className='no_side_padding'>
                        <MDBCol className="no_side_padding">
                            <SwipeableViews 
                                index={index} 
                                // animateHeight
                            >
                                {Object.entries(tabPanels).map(([key, value]) => {
                                    return (
                                        renderTabItem(value, key)
                                    );
                                })}
                            </SwipeableViews>
                        </MDBCol>
                    </MDBRow>
                }
            </MDBRow> 
        </>
    );
};

export default FixtureDashboard;
