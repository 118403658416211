import { useState, useRef, useEffect } from "react";
import { useResults } from "../fixtures/fixtureHook";
import { BsClockFill } from 'react-icons/bs';
import { MDBRow, MDBCol } from 'mdbreact';
import { SetStatusClasses, GetFixtureState } from "../../common/helpers/status_helper";
import LiveScoreWidgetSkeleton from "../loading/skeletons/live_Score_widget_skeleton";
import { useIsVisible } from "../shared/hooks/isVisibleHook";
import { useLiveScoresSubscription } from "../infrastructure/subscriptions/fixtureSubscriptionHook";
import { DateController } from "../shared/date/button_date_picker";
import '../dashboards/css/fixtures_and_Results_dashboard.css';
import { Paper, Tooltip, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import Divider, { dividerClasses } from '@mui/material/Divider';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function LiveScoresWidget({ currentLeagueID, isMobile, contentSetter, site_date, switch_site_date, content_type }) {
    const componentMounted = useRef(true);
    const [resultsState, setFixtureResultsState] = useState({ isLoading: true, data: null });
    const [isVisible, setIsVisible] = useState(false);
    const [showLoading, setShowLoading] = useState(true);

    useIsVisible(isVisible, setIsVisible, componentMounted);
    useResults(site_date, currentLeagueID, setFixtureResultsState);
    useLiveScoresSubscription(site_date, setFixtureResultsState);

    useEffect(() => {
        if (!resultsState.isLoading) {
            const timeout = setTimeout(() => {
                setShowLoading(false);
            }, 300);

            return () => clearTimeout(timeout);
        }
    }, [resultsState.isLoading]);

    if ((!resultsState.data && resultsState.isLoading) || showLoading) {
        return <LiveScoreWidgetSkeleton />;
    }

    const { data } = resultsState;

    const filteredLeagues = data?.league_profiles?.filter((league) =>
        currentLeagueID === -1 || currentLeagueID === null ? true : league.id === currentLeagueID
    );

    return (
        <Paper elevation={1} sx={{[`& .${dividerClasses.root}`]: { mx: 0, height: '0.5px', opacity: '0.1' }}} className={`dashboard_widget br-15 ${isVisible ? "fade-in" : ""}`}>
            <label className="dashboard_widget_controls">
                <span>Scores</span>
                <span>
                    <DateController selected_date={site_date} set_date={switch_site_date} />
                </span>
            </label>
            <Divider sx={{opacity: 0.1, height: '0.5px'}} className="mt-1 mb-2" variant="middle" />
            <div style={{ paddingBottom: "15px" }}>
                {
                (!filteredLeagues || filteredLeagues.length < 1) ? (
                    <MDBRow>
                        <MDBCol size="12" className="p-5">
                            <BsClockFill color="#0D1E33" style={{ margin: "0 auto", display: "block" }} size={100} />
                        </MDBCol>
                        <MDBCol size="12">
                            <p className="label_no_results_heading">No Scheduled Fixtures</p>
                            <p className="label_no_results_sub_heading">Try selecting a future date from the date picker above to view upcoming fixtures.</p>
                            <p className="label_no_results_sub_heading">Alternatively select a date from the past to view past results and statistics.</p>
                        </MDBCol>
                    </MDBRow>
                ) : 
                (
                    filteredLeagues.map((league, index) => (
                        <div key={`competition_${league.id}`} className="event_details_row">
                            <div className="event_competition_row">
                                <img
                                    className="image_competition_logo"
                                    src={league.logo}
                                    alt={`${league.name} logo`}
                                />
                                <div className="div_competition_labels">
                                    <Typography component="div">{league.name}</Typography>
                                </div>
                            </div>

                            {data.results.filter((result) => result.league_id === league.id).map((result) => (
                                <Link
                                    key={`event_row_${result.fixture_id}`}
                                    to={
                                        content_type === 8
                                            ? `/fixture/${result.fixture_id}/${result.fixture_name}/stat-pack`
                                            : `/fixture/${result.fixture_id}/${result.fixture_name}`
                                    }
                                >
                                    <div
                                        onClick={() => contentSetter(content_type, result.id)}
                                        className="clickable event_row"
                                    >
                                        <div className="event_container">
                                            <div className="event_information">
                                                <div className="event_state">
                                                    <div className="event_time_status">
                                                        <span className="event_date">{result.fixture_details.time}</span>
                                                        <div
                                                            className={`current_event_state ${SetStatusClasses(result.fixture_state.state)} event_status`}
                                                        >
                                                            {result.fixture_state.state === "NS" ? "-" : GetFixtureState(result.fixture_state, result.fixture_details.time, false)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="event_column_teams">
                                                    <div className="live_score_teams">
                                                        <div className="teams_container">
                                                            <div className="team_name">
                                                                <img
                                                                    src={result.home_team.logo}
                                                                    alt={result.home_team.name + " logo"}
                                                                />
                                                                {result.home_team.name}
                                                            </div>
                                                            <div className="team_name">
                                                                <img
                                                                    src={result.away_team.logo}
                                                                    alt={result.away_team.name + " logo"}
                                                                />
                                                                {result.away_team.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    {result.has_highlights ? <Tooltip describeChild title="Highlights available"><YouTubeIcon style={{ fontSize: '15px', color: '#FF0000' }} /></Tooltip> : <></>}
                                                </div>
                                                <div className="event_column_scores">
                                                    <div className="event_result">
                                                        <div className="score_container">
                                                            <span className={`${SetStatusClasses(result.fixture_state.state)}`}>
                                                                <b>{result.home_win ? result.fixture_state.home_team_score : ""}</b>
                                                                {!result.home_win ? result.fixture_state.home_team_score : ""}
                                                            </span>
                                                            <span className={`${SetStatusClasses(result.fixture_state.state)}`}>
                                                                <b>{result.away_win ? result.fixture_state.away_team_score : ""}</b>
                                                                {!result.away_win ? result.fixture_state.away_team_score : ""}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))}

                            {index !== filteredLeagues.length - 1 && <Divider sx={{opacity: 0.1}} className="mt-2 mb-2" variant="middle" />}
                        </div>
                    ))
                )}
            </div>
        </Paper>
    );
}
