import { Paper } from "@mui/material";
import Divider, { dividerClasses } from '@mui/material/Divider';
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import { useFixtureGoalResults } from "./fixtureHook";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const styles = {
    goalResultGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 200px 1fr'
    },
    valueColumn: {
        textAlign: 'center',
        padding: '10px',
        fontSize: '14px'
    },
    logo: {
        width: '30px',
        height: '30px'
    },
    footer: {
        fontSize: '12px',
        padding: '10px 10px',
        color: '#737373'
    }
}

export default function FixtureGoalResults({ fixture_id, fixture, teams, contentSetter, schedule }) {

    const [goalResultsResponse, setGoalResultsResponse] = useState({ isLoading: true, data: null });

    useFixtureGoalResults(fixture_id, setGoalResultsResponse);

    if(!goalResultsResponse || !goalResultsResponse.data || goalResultsResponse.isLoading) {
        return <></>
    }

    const { home_team_goal_results, away_team_goal_results } = goalResultsResponse.data;

    return (
        <Paper elevation={1} className="mb-2 br-15 widget_fade_in no_side_padding" sx={{[`& .${dividerClasses.root}`]: { mx: 0, height: '0.5px', opacity: '0.1' }}}>
            <div className='widget_heading' style={{textAlign: 'center', padding: '10px 10px 10px 10px' }}>
                Goal Results
            </div>
            <Divider />
            <MDBRow style={styles.goalResultGrid}>
                <div style={styles.valueColumn}><img style={styles.logo} src={teams.home_team.logo} alt={teams.home_team.name + " logo"} /></div>
                <div style={styles.valueColumn}></div>
                <div style={styles.valueColumn}><img style={styles.logo} src={teams.away_team.logo} alt={teams.away_team.name + " logo"} /></div>
            </MDBRow>
            <Divider />
            <MDBRow style={styles.goalResultGrid}>
                <div style={styles.valueColumn}>{home_team_goal_results.gf_over_0_5}</div>
                <div style={styles.valueColumn}>Goals for over 0.5</div>
                <div style={styles.valueColumn}>{away_team_goal_results.gf_over_0_5}</div>
            </MDBRow>
            <Divider />
            <MDBRow style={styles.goalResultGrid}>
                <div style={styles.valueColumn}>{home_team_goal_results.gf_over_1_5}</div>
                <div style={styles.valueColumn}>Goals for over 1.5</div>
                <div style={styles.valueColumn}>{away_team_goal_results.gf_over_1_5}</div>
            </MDBRow>
            <Divider />
            <MDBRow style={styles.goalResultGrid}>
                <div style={styles.valueColumn}>{home_team_goal_results.gf_over_2_5}</div>
                <div style={styles.valueColumn}>Goals for over 2.5</div>
                <div style={styles.valueColumn}>{away_team_goal_results.gf_over_2_5}</div>
            </MDBRow>
            <Divider />
            <MDBRow style={styles.goalResultGrid}>
                <div style={styles.valueColumn}>{home_team_goal_results.gf_over_3_5}</div>
                <div style={styles.valueColumn}>Goals for over 3.5</div>
                <div style={styles.valueColumn}>{away_team_goal_results.gf_over_3_5}</div>
            </MDBRow>
            <Divider />
            <MDBRow style={styles.goalResultGrid}>
                <div style={styles.valueColumn}>{home_team_goal_results.gf_over_4_5}</div>
                <div style={styles.valueColumn}>Goals for over 4.5</div>
                <div style={styles.valueColumn}>{away_team_goal_results.gf_over_4_5}</div>
            </MDBRow>
            <Divider />
            <MDBRow style={styles.goalResultGrid}>
                <div style={styles.valueColumn}>{home_team_goal_results.ga_over_0_5}</div>
                <div style={styles.valueColumn}>Goals against over 0.5</div>
                <div style={styles.valueColumn}>{away_team_goal_results.ga_over_0_5}</div>
            </MDBRow>
            <Divider />
            <MDBRow style={styles.goalResultGrid}>
                <div style={styles.valueColumn}>{home_team_goal_results.ga_over_1_5}</div>
                <div style={styles.valueColumn}>Goals against over 1.5</div>
                <div style={styles.valueColumn}>{away_team_goal_results.ga_over_1_5}</div>
            </MDBRow>
            <Divider />
            <MDBRow style={styles.goalResultGrid}>
                <div style={styles.valueColumn}>{home_team_goal_results.ga_over_2_5}</div>
                <div style={styles.valueColumn}>Goals against over 2.5</div>
                <div style={styles.valueColumn}>{away_team_goal_results.ga_over_2_5}</div>
            </MDBRow>
            <Divider />
            <MDBRow style={styles.goalResultGrid}>
                <div style={styles.valueColumn}>{home_team_goal_results.ga_over_3_5}</div>
                <div style={styles.valueColumn}>Goals against over 3.5</div>
                <div style={styles.valueColumn}>{away_team_goal_results.ga_over_3_5}</div>
            </MDBRow>
            <Divider />
            <MDBRow style={styles.goalResultGrid}>
                <div style={styles.valueColumn}>{home_team_goal_results.ga_over_4_5}</div>
                <div style={styles.valueColumn}>Goals against over 4.5</div>
                <div style={styles.valueColumn}>{away_team_goal_results.ga_over_4_5}</div>
            </MDBRow>
            <Divider />
            <MDBRow style={styles.footer}>
                <MDBCol>
                    <HelpOutlineIcon sx={{fontSize: '14px', position: 'relative', top: '-2px'}} /> Over X goal distribution for 
                    {" "}{teams.home_team.name}{" "}
                    {" "}({home_team_goal_results.games_played} games, {home_team_goal_results.home_games_played} home and {home_team_goal_results.away_games_played} away) and 
                    {" "}{teams.away_team.name}{" "}
                    {" "}({away_team_goal_results.games_played} games, {away_team_goal_results.home_games_played} home and {away_team_goal_results.away_games_played} away) for 
                    the{" "}{schedule.data.league.name}
                </MDBCol>
            </MDBRow>
            <Divider />
            <div style={{padding: '8px', display: 'flex', justifyContent: 'center', fontSize: '12px'}}>
                <Link className="anchor-tag no_side_padding" style={{textDecoration: 'none'}} to={`/fixture/${fixture_id}/${fixture.fixture_name}/stat-pack`}>
                    <div 
                        onClick={() => contentSetter(14, fixture_id)}
                        style={{
                            textAlign: 'center',
                            padding: '4px',
                            width: '200px'
                        }}
                    >
                        View stat pack <KeyboardArrowRightIcon />
                    </div>
                </Link>     
            </div>
        </Paper>
    )
}
